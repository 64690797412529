import {
  Networks,
  getEnumName,
  getEnumValues,
  isEmptyString,
  isNotEmptyString,
  isNotNil,
  parseEnumString,
} from '@frontend2/core';
import { getNetworkOfPostType } from '@frontend2/proto-helpers/common/common_pb.helpers';
import {
  readableInfluencerState,
  readableInfluencerStatus,
} from '@frontend2/proto-helpers/librarian/campaign_pb.helpers';
import {
  InfluencerState,
  InfluencerStatus,
} from '@frontend2/proto/common/proto/campaign_pb';
import { Network, PostType } from '@frontend2/proto/common/proto/common_pb';
import { Campaign } from '@frontend2/proto/librarian/proto/campaigns_pb';
import {
  CommonFilter,
  EntityField,
} from '@frontend2/proto/librarian/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { RfmScoreValue } from '@frontend2/proto/librarian/proto/rfm_scoring_pb';
import { readableNetwork } from '../../../followers-range-dropdown/followers-range-dropdown.helpers';
import { DynamicFilterConfig } from '../../dynamic-filters-config.bloc';
import {
  getDynamicListFilterValue,
  isCampaignDynamicFilter,
  isCampaignLabelDynamicFilter,
  isDirectoryInfluencerDynamicFilter,
  isInfluencerDynamicFilter,
  isInfluencerStateDynamicFilter,
  isInfluencerStatusDynamicFilter,
  isLabelDynamicFilter,
  isNetworkDynamicFilter,
  isPostLabelDynamicFilter,
  isRfmScoreDynamicFilter,
} from '../../dynamic-filters.helpers';

export type ListOperator = 'isAnyOf' | 'isNoneOf' | 'isEmpty';

export function getListOperatorFromFilter(filter: CommonFilter): ListOperator {
  if (filter.filter.case === 'isEmpty' && filter.filter.value === true) {
    return 'isEmpty';
  }
  const listFilter = getDynamicListFilterValue(filter);
  switch (listFilter.value.case) {
    case 'anyOf': {
      return 'isAnyOf';
    }
    case 'noneOf': {
      return 'isNoneOf';
    }
    default: {
      return 'isEmpty';
    }
  }
}

export function readableListOperator(operator: ListOperator): string {
  switch (operator) {
    case 'isAnyOf':
      return 'is any of';
    case 'isNoneOf':
      return 'is none of';
    case 'isEmpty':
      return 'is empty';
  }
}

export interface ListOptionValues {
  readonly availableCampaigns: Map<bigint, Campaign>;
  readonly availableCampaignLabels: Map<bigint, EntityLabel>;
  readonly availableInfluencerLabels: Map<bigint, EntityLabel>;
  readonly availablePostLabels: Map<bigint, EntityLabel>;
  readonly availableInfluencers: Map<string, CreatorTiniestCard>;
  readonly availableCreatorFields: EntityField[];
  readonly availableCampaignFields: EntityField[];
  readonly availableNetworks: Network[];
}

export function createListOptionValues(
  partial?: Partial<ListOptionValues>,
): ListOptionValues {
  return {
    availableCampaigns:
      partial?.availableCampaigns ?? new Map<bigint, Campaign>(),
    availableCampaignLabels:
      partial?.availableCampaignLabels ?? new Map<bigint, EntityLabel>(),
    availableInfluencerLabels:
      partial?.availableInfluencerLabels ?? new Map<bigint, EntityLabel>(),
    availablePostLabels:
      partial?.availablePostLabels ?? new Map<bigint, EntityLabel>(),
    availableInfluencers:
      partial?.availableInfluencers ?? new Map<string, CreatorTiniestCard>(),
    availableCreatorFields: partial?.availableCreatorFields ?? [],
    availableCampaignFields: partial?.availableCampaignFields ?? [],
    availableNetworks: partial?.availableNetworks ?? [],
  };
}

export function initializeListOptionValues(
  value: CommonFilter,
  listOptionValues: ListOptionValues,
): string[] {
  let options: string[] = [];
  if (isCampaignDynamicFilter(value)) {
    options = Array.from(listOptionValues.availableCampaigns.keys()).map((e) =>
      e.toString(),
    );
  } else if (isLabelDynamicFilter(value)) {
    options = _buildLabelsOptions(listOptionValues.availableInfluencerLabels);
  } else if (isCampaignLabelDynamicFilter(value)) {
    options = _buildLabelsOptions(listOptionValues.availableCampaignLabels);
  } else if (isPostLabelDynamicFilter(value)) {
    options = _buildLabelsOptions(listOptionValues.availablePostLabels);
  } else if (isInfluencerDynamicFilter(value)) {
    options = Array.from(listOptionValues.availableInfluencers.keys());
  } else if (isNetworkDynamicFilter(value)) {
    options = listOptionValues.availableNetworks.map((e) => e.toString());
  } else if (isInfluencerStatusDynamicFilter(value)) {
    options = getEnumValues<InfluencerStatus>(InfluencerStatus)
      .map((e) => getEnumName(InfluencerStatus, e))
      .filter(isNotEmptyString);
  } else if (isInfluencerStateDynamicFilter(value)) {
    options = getEnumValues<InfluencerState>(InfluencerState)
      .filter((s) => s !== InfluencerState.STATE_UNDEFINED)
      .map((e) => getEnumName(InfluencerState, e))
      .filter(isNotEmptyString);
  } else if (isRfmScoreDynamicFilter(value)) {
    options = getEnumValues<RfmScoreValue>(RfmScoreValue)
      .filter((r) => r !== RfmScoreValue.UNKNOWN)
      .map((e) => getEnumName(RfmScoreValue, e))
      .filter(isNotEmptyString);
  } else if (value.field.case === 'entityField') {
    options = value.field.value.listValues;
  }
  return options;
}

function _buildLabelsOptions(labels: Map<bigint, EntityLabel>): string[] {
  const entries = Array.from(labels.entries());

  const filteredEntries = entries.filter(
    ([, value]) => isNotNil(value.count) && value.count > 0,
  );

  return filteredEntries.map((e) => e[0].toString());
}

export function readableListOptions(
  id: string,
  value: CommonFilter,
  config: DynamicFilterConfig,
): string {
  const listOptionValues = config.listOptionValues;
  if (isEmptyString(id)) {
    return '';
  }

  if (isCampaignDynamicFilter(value)) {
    return (
      listOptionValues.availableCampaigns.get(BigInt(parseInt(id)))?.name ?? ''
    );
  } else if (isLabelDynamicFilter(value)) {
    return (
      listOptionValues.availableInfluencerLabels.get(BigInt(parseInt(id)))
        ?.name ?? ''
    );
  } else if (isCampaignLabelDynamicFilter(value)) {
    return (
      listOptionValues.availableCampaignLabels.get(BigInt(parseInt(id)))
        ?.name ?? ''
    );
  } else if (isInfluencerDynamicFilter(value)) {
    if (isDirectoryInfluencerDynamicFilter(value, config)) {
      return (
        config.directoryInfluencers.find((c) => c.baseSnippet?.userId === id)
          ?.baseSnippet?.userName ?? ''
      );
    } else {
      return listOptionValues.availableInfluencers.get(id)?.userName ?? '';
    }
  } else if (isPostLabelDynamicFilter(value)) {
    return (
      listOptionValues.availablePostLabels.get(BigInt(parseInt(id)))?.name ?? ''
    );
  } else if (isNetworkDynamicFilter(value)) {
    return readableNetwork(parseInt(id) ?? 0);
  } else if (isInfluencerStatusDynamicFilter(value)) {
    return readableInfluencerStatus(parseEnumString(InfluencerStatus, id) ?? 0);
  } else if (isInfluencerStateDynamicFilter(value)) {
    return readableInfluencerState(parseEnumString(InfluencerState, id) ?? 0);
  } else if (isRfmScoreDynamicFilter(value)) {
    return id;
  } else if (value.field.case === 'entityField') {
    return id;
  } else {
    return '';
  }
}

export function postTypeFilterFormattedValue(filter: CommonFilter): string {
  const listFilter = getDynamicListFilterValue(filter);
  const networks = new Set<Network>();

  const selectedTypesString = listFilter.value.value?.values ?? [];
  const selectedtypes = selectedTypesString
    .map((s) => parseEnumString<PostType>(PostType, s))
    .filter(isNotNil);

  for (const postType of selectedtypes) {
    const network = getNetworkOfPostType(postType);
    networks.add(network);
  }
  return [...networks].map((n) => Networks.readable(n)).join(', ');
}
