import {
  ChangeDetectionStrategy,
  Component,
  Output,
  computed,
  inject,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  LeftyAuthBloc,
  injectLibrarianEcommerceStoresClient,
} from '@frontend2/api';
import { isGhostManualStore } from '@frontend2/proto-helpers/librarian/ecommerce_stores_pb.helpers';
import { CurrencyEnum } from '@frontend2/proto/common/proto/common_pb';
import {
  CreateManualStoreRequest,
  EditManualStoreRequest,
  ManualStorePb,
} from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import {
  DialogBase,
  LeftyCurrencySelectorComponent,
  LeftyDialogComponent,
  LeftyFormInputComponent,
  createOutput,
  injectToastManager,
  showToastException,
} from '@frontend2/ui';
import { AlfredEventsBus } from '../../events/events';

@Component({
  selector: 'alfred-add-or-edit-manual-store-dialog',
  templateUrl: './add-or-edit-manual-store-dialog.component.html',
  styleUrls: ['./add-or-edit-manual-store-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyFormInputComponent,
    ReactiveFormsModule,
    FormsModule,
    LeftyCurrencySelectorComponent,
  ],
})
export class AddOrEditManualStoreDialogComponent extends DialogBase {
  private readonly auth = inject(LeftyAuthBloc);
  private readonly librarianEcommerce = injectLibrarianEcommerceStoresClient();
  private readonly toastManager = injectToastManager();
  private readonly alfredEvents = inject(AlfredEventsBus);

  @Output()
  readonly storeCreated$ = createOutput<bigint>();

  get workspaceCurrency(): CurrencyEnum {
    return this.auth.currency;
  }

  readonly loading = signal(false);

  readonly isEdit = computed(() => !isGhostManualStore(this.currentStore()));

  readonly title = computed(() => {
    return this.isEdit()
      ? $localize`Edit “${this.currentStore().name}” store`
      : $localize`Add new offline store`;
  });

  submitButtonText = computed(() =>
    this.isEdit() ? $localize`Edit` : $localize`Add`,
  );

  currentStore = signal(new ManualStorePb());

  formModel = new FormGroup({
    title: new FormControl<string>('', Validators.required),
    url: new FormControl<string>(''),
    currency: new FormControl<CurrencyEnum>(
      this.workspaceCurrency,
      Validators.required,
    ),
  });

  openWith(store?: ManualStorePb): void {
    this.currentStore.set(store ?? new ManualStorePb());
    if (this.isEdit()) {
      this.populateFormModel();
    }
    this.open();
  }

  populateFormModel(): void {
    this.formModel.patchValue({
      title: this.currentStore().name,
      url: this.currentStore().domain ?? '',
      currency: this.currentStore().currency,
    });
  }

  submit(): void {
    if (this.isEdit()) {
      this.editManualStore();
    } else {
      this.createManualStore();
    }
  }

  async createManualStore(): Promise<void> {
    const request = new CreateManualStoreRequest({
      name: this.formModel.get('title')?.value ?? '',
      domain: this.formModel.get('url')?.value ?? '',
      currency: this.formModel.get('currency')?.value ?? this.workspaceCurrency,
    });

    try {
      this.loading.set(true);
      const newStore =
        await this.librarianEcommerce.createManualStoreAPI(request);
      this.toastManager.showSuccess($localize`Store created with success.`);
      this.alfredEvents.emit(
        'create_or_update_manual_store',
        newStore.manualStore ?? new ManualStorePb(),
      );
      this.storeCreated$.next(newStore.manualStore?.id ?? BigInt(0));
      this.close();
    } catch (e) {
      showToastException(this.toastManager, e);
    } finally {
      this.loading.set(false);
    }
  }

  async editManualStore(): Promise<void> {
    const request = new EditManualStoreRequest({
      id: this.currentStore().id,
      name: this.formModel.get('title')?.value ?? '',
      domain: this.formModel.get('url')?.value ?? '',
    });

    try {
      this.loading.set(true);
      const newStore =
        await this.librarianEcommerce.editManualStoreAPI(request);
      this.toastManager.showSuccess($localize`Store edited with success.`);
      this.alfredEvents.emit(
        'create_or_update_manual_store',
        newStore.manualStore ?? new ManualStorePb(),
      );
      this.close();
    } catch (e) {
      showToastException(this.toastManager, e);
    } finally {
      this.loading.set(false);
    }
  }

  resetForm(): void {
    this.formModel.reset({ currency: this.workspaceCurrency });
    this.currentStore.set(new ManualStorePb());
  }
}
