import { Injectable, computed } from '@angular/core';
import { injectLibrarianEcommerceStoresClient } from '@frontend2/api';
import { isNotEmptyString } from '@frontend2/core';
import {
  CreateSalesforceStoresRequest,
  GetSalesforceSlasAdminUILinkRequest,
  SalesforceStorePb,
  ValidateCreateSalesforceStoresAPIClientRequest,
  ValidateCreateSalesforceStoresAPIClientResponse,
  ValidateCreateSalesforceStoresSlasAdminTokenRequest,
} from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import { Bloc } from '@frontend2/ui';
import {
  AddSalesforceStoreState,
  createAddSalesforceStoreState,
} from './add-salesforce-store.models';

//a bloc that will keep the state all along the process of adding a salesforce store
@Injectable()
export class AddSalesforceStoreBloc extends Bloc<AddSalesforceStoreState> {
  private readonly _librarianEcommerce = injectLibrarianEcommerceStoresClient();

  constructor() {
    super(createAddSalesforceStoreState());
  }

  readonly currentRequest = computed(() => this.state().request);

  setStepOneData(shortCode: string, organizationId: string): void {
    this._setRequest(
      new CreateSalesforceStoresRequest({
        ...this.currentRequest(),
        shortCode: shortCode,
        organizationId: organizationId,
      }),
    );
  }

  setStepTwoData(apiClientId: string, clientSecret: string): void {
    this._setRequest(
      new CreateSalesforceStoresRequest({
        ...this.currentRequest(),
        clientId: apiClientId,
        clientSecret: clientSecret,
      }),
    );
  }

  setStepThreeData(adminToken: string): void {
    this._setRequest(
      new CreateSalesforceStoresRequest({
        ...this.currentRequest(),
        slasAdminToken: adminToken,
      }),
    );
  }

  setStepFourData(ids: string[]): void {
    this._setRequest(
      new CreateSalesforceStoresRequest({
        ...this.currentRequest(),
        siteIds: ids,
      }),
    );
  }

  private _setRequest(req: CreateSalesforceStoresRequest): void {
    this.setState(
      createAddSalesforceStoreState({
        ...this.state(),
        request: req,
      }),
    );
  }

  async getBroncoTaskHandle(): Promise<string> {
    const token = await this._librarianEcommerce.createSalesforceStoresAPI(
      this.currentRequest(),
    );

    this.setState(
      createAddSalesforceStoreState({
        ...this.state(),
        broncoTaskHandle: token.taskHandle,
      }),
    );
    return token.taskHandle;
  }

  async setCreatedStores(stores: SalesforceStorePb[]): Promise<void> {
    this.setState(
      createAddSalesforceStoreState({
        ...this.state(),
        createdStores: stores,
      }),
    );
  }

  async setSalesforceSlasAdminUILink(): Promise<void> {
    const shortCode = this.currentRequest().shortCode;
    if (isNotEmptyString(shortCode)) {
      const resp =
        await this._librarianEcommerce.getSalesforceSlasAdminUILinkAPI(
          new GetSalesforceSlasAdminUILinkRequest({ shortCode: shortCode }),
        );
      this.setState(
        createAddSalesforceStoreState({
          ...this.state(),
          salesforceSlasAdminUILink: resp.slasAdminUiLink,
        }),
      );
    }
  }

  async getApiClientError(): Promise<ValidateCreateSalesforceStoresAPIClientResponse> {
    const req = new ValidateCreateSalesforceStoresAPIClientRequest({
      clientId: this.currentRequest().clientId,
      clientSecret: this.currentRequest().clientSecret,
      shortCode: this.currentRequest().shortCode,
      organizationId: this.currentRequest().organizationId,
    });
    const resp =
      await this._librarianEcommerce.validateCreateSalesforceStoresAPIClientAPI(
        req,
      );
    this.setState(
      createAddSalesforceStoreState({
        ...this.state(),
        invalidFieldName: resp.error.case,
        errorMessage: resp.error.value,
      }),
    );
    return resp;
  }

  async getSlasAdminTokenError(): Promise<boolean> {
    const req = new ValidateCreateSalesforceStoresSlasAdminTokenRequest({
      slasAdminToken: this.currentRequest().slasAdminToken,
      shortCode: this.currentRequest().shortCode,
      organizationId: this.currentRequest().organizationId,
    });
    const resp =
      await this._librarianEcommerce.validateCreateSalesforceStoresSlasAdminTokenAPI(
        req,
      );

    this.setState(
      createAddSalesforceStoreState({
        ...this.state(),
        errorMessage: resp.error,
      }),
    );

    return isNotEmptyString(resp.error);
  }

  resetState(): void {
    this.setState(createAddSalesforceStoreState());
  }
}
