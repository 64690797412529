<lefty-dialog
  [(visible)]="visible"
  size="small"
  [hideFooter]="true"
  [hideCloseButton]="true"
  [autoDismissable]="false"
>
  <h3
    title
    i18n
  >
    Connecting stores
  </h3>
  <div class="container">
    <p
      class="description"
      i18n
    >
      We are looking for your stores available on Salesforce Commerce Cloud.
      Please wait a few moments.
    </p>

    <div class="progress">
      {{ formattedLoadingProgress() }}

      <lefty-progress
        [activeProgress]="loadingProgress()"
        [max]="100"
      ></lefty-progress>
    </div>
  </div>
</lefty-dialog>
