import {
  ChangeDetectionStrategy,
  Component,
  viewChildren,
} from '@angular/core';
import { isNotEmptyArray } from '@frontend2/core';
import { CreateSalesforceStoresRequest } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import {
  LeftyButtonDirective,
  LeftyDialogComponent,
  LeftyFeedbackComponent,
  LeftyFormInputComponent,
} from '@frontend2/ui';
import { AddSalesforceStepsComponent } from '../abstract-steps';

@Component({
  selector: 'alfred-add-salesforce-step-four-dialog',
  templateUrl: 'add-salesforce-step-four-dialog.component.html',
  styleUrls: ['../shared-dialogs-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyFeedbackComponent,
    LeftyButtonDirective,
    LeftyFormInputComponent,
  ],
})
export class AlfredAddSalesforceStepFourDialogComponent extends AddSalesforceStepsComponent {
  storeNames: string[] = [];

  readonly nameInputs = viewChildren<LeftyFormInputComponent>('realInputs');

  override populateFormWithRequest(req: CreateSalesforceStoresRequest): void {
    this.storeNames = [...req.siteIds];
  }

  override setStateOfService(): void {
    this.service.setStepFourData(this.storeNames);
  }

  get hasStores(): boolean {
    return isNotEmptyArray(this.storeNames);
  }

  deleteField(index: number): void {
    if (this.storeNames && this.storeNames.length > index) {
      this.storeNames.splice(index, 1);
      this.changeDetection.markForCheck();
    }
  }

  addField(): void {
    this.storeNames = [...this.storeNames, ''];
    this.focusOnLastInput();
    this.changeDetection.markForCheck();
  }

  focusOnLastInput(): void {
    setTimeout(() => {
      const lastInput = this.nameInputs().at(this.nameInputs().length - 1);
      if (lastInput) {
        lastInput.focus();
      }
    }, 0);
  }
}
