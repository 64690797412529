import { Component, computed, inject, output, signal } from '@angular/core';
import { isNotEmptyString } from '@frontend2/core';
import { CreateSalesforceStoresRequest } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import { DialogBase } from '@frontend2/ui';
import { SALESFORCE_INTEGRATION_GUIDE_URL } from '../../../routes.constants';
import { AddSalesforceStoreBloc } from './add-salesforce-store-bloc';

@Component({
  template: '',
})
export abstract class AddSalesforceStepsComponent extends DialogBase {
  readonly guideUrl = SALESFORCE_INTEGRATION_GUIDE_URL;
  readonly service = inject(AddSalesforceStoreBloc);

  readonly currentRequest = this.service.currentRequest;

  readonly invalidFieldName = computed(
    () => this.service.state().invalidFieldName,
  );

  readonly errorMessage = computed(() => {
    return isNotEmptyString(this.service.state().errorMessage)
      ? this.service.state().errorMessage
      : $localize`Invalid information. Please check again.`;
  });

  readonly next$ = output<void>();

  readonly back$ = output<void>();

  readonly loading = signal(false);

  readonly showError = signal(false);

  abstract populateFormWithRequest(req: CreateSalesforceStoresRequest): void;

  abstract setStateOfService(): void;

  override open(): void {
    super.open();
    this.populateFormWithRequest(this.currentRequest());
  }

  next(): void {
    this.setStateOfService();
    this.close();
    this.next$.emit();
  }

  back(): void {
    this.close();
    this.back$.emit();
  }
}
