<lefty-dialog
  [(visible)]="visible"
  size="small"
>
  <h3
    title
    i18n
  >
    Step 4: Adding stores
  </h3>
  <div class="basic-container">
    <lefty-feedback
      [hideIcon]="true"
      i18n
    >
      <p class="fill-space">
        Provide the exact names (capitalization sensitive) of the Salesforce
        Commerce Cloud stores you wish to connect with Lefty.
      </p>
    </lefty-feedback>
    <div class="store-name-inputs">
      @for (item of storeNames; track $index) {
        <div class="flex row vCenter store-name-item">
          <lefty-form-input
            class="fill-space"
            (valueChange)="storeNames[$index] = $event"
            [value]="item"
            #realInputs
            placeholder="Store name"
            i18n-placeholder
          ></lefty-form-input>

          <button
            leftyButton="plain"
            icon="delete"
            class="delete-icon"
            (click)="deleteField($index)"
          ></button>
        </div>
      }

      <!-- this is not a real input, it work as a button to add other inputs -->
      <div class="flex row vCenter store-name-item">
        <lefty-form-input
          class="fill-space"
          (focus$)="addField()"
          placeholder="Store name"
          i18n-placeholder
        ></lefty-form-input>
        @if (hasStores) {
          <button
            leftyButton="plain"
            icon="delete"
            class="delete-icon hidden-button"
          ></button>
        }
      </div>
    </div>
  </div>
  <ng-container footer>
    <button
      leftyButton="secondary"
      i18n
      (click)="back()"
    >
      Back
    </button>
    <button
      leftyButton="primary"
      i18n
      (click)="next()"
      [disabled]="hasStores === false"
    >
      Connect
    </button>
  </ng-container>
</lefty-dialog>
