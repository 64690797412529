import { VERSION } from '@frontend2/core';
import { initLeftyApp } from '@frontend2/ui';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

initLeftyApp(
  'alfred',
  AppModule,
  { ...environment, version: VERSION },
  {
    posthogConfig: {
      // track event only for user logged in
      person_profiles: 'identified_only',
      session_recording: {
        // necessary to record session when app is in IFRAME
        recordCrossOriginIframes: true,
      },
    },
  },
);
