import { ChangeDetectionStrategy, Component, effect } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CreateSalesforceStoresRequest } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import {
  LeftyButtonDirective,
  LeftyDialogComponent,
  LeftyFeedbackComponent,
  LeftyFormInputComponent,
  LeftyIconComponent,
} from '@frontend2/ui';
import { AddSalesforceStepsComponent } from '../abstract-steps';

@Component({
  selector: 'alfred-add-salesforce-step-one-dialog',
  templateUrl: 'add-salesforce-step-one-dialog.component.html',
  styleUrls: ['../shared-dialogs-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyFeedbackComponent,
    LeftyButtonDirective,
    LeftyFormInputComponent,
    LeftyIconComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class AlfredAddSalesforceStepOneDialogComponent extends AddSalesforceStepsComponent {
  constructor() {
    super();
    effect(() => {
      if (this.showError() === true) {
        if (this.invalidFieldName() === 'organizationIdError') {
          this.formModel.controls['organizationId'].setErrors({
            incorrect: true,
          });
        } else if (this.invalidFieldName() === 'shortCodeError') {
          this.formModel.controls['shortCode'].setErrors({ incorrect: true });
        }
        this.formModel.markAllAsTouched();
      }
    });
  }

  readonly formModel: FormGroup = new FormGroup({
    shortCode: new FormControl<string>('', Validators.required),
    organizationId: new FormControl<string>('', Validators.required),
  });

  override populateFormWithRequest(req: CreateSalesforceStoresRequest): void {
    this.formModel.reset({
      shortCode: req.shortCode,
      organizationId: req.organizationId,
    });
  }

  setStateOfService(): void {
    const formValue = this.formModel.value;
    this.service.setStepOneData(formValue.shortCode, formValue.organizationId);
  }

  openWithError(hasError: boolean): void {
    this.showError.set(hasError);
    this.open();
  }
}
