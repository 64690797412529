import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  output,
  signal,
} from '@angular/core';
import { isNotEmptyString, isNotNil, LeftyFormat } from '@frontend2/core';
import { CreateSalesforceStoresTaskOutput } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import {
  BroncoService,
  DialogBase,
  injectToastManager,
  LeftyDialogComponent,
  LeftyProgressComponent,
  showToastException,
} from '@frontend2/ui';
import { map } from 'rxjs';
import { injectAlfredEventBus } from '../../../events/events';
import { AddSalesforceStoreBloc } from '../add-salesforce-store-bloc';

@Component({
  selector: 'alfred-add-salesforce-connecting-dialog',
  templateUrl: 'add-salesforce-connecting-dialog.component.html',
  styleUrls: [
    './add-salesforce-connecting-dialog.component.scss',
    '../shared-dialogs-styles.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyDialogComponent, LeftyProgressComponent],
})
export class AlfredAddSalesforceConnectingDialogComponent extends DialogBase {
  readonly service = inject(AddSalesforceStoreBloc);
  private readonly _bronco = inject(BroncoService);
  private readonly eventBus = injectAlfredEventBus();
  private readonly toastManager = injectToastManager();

  readonly loadingProgress = signal(0);

  readonly formattedLoadingProgress = computed(() =>
    LeftyFormat.percent(this.loadingProgress() / 100, {
      maximumFractionDigits: 0,
      showZero: true,
    }),
  );

  readonly next$ = output<void>();

  readonly back$ = output<void>();

  override async open(): Promise<void> {
    super.open();
    this._subscribeBronco();
  }

  private async _subscribeBronco(): Promise<void> {
    try {
      const broncoTaskHandle = await this.service.getBroncoTaskHandle();
      if (isNotEmptyString(broncoTaskHandle)) {
        const broncoSubscription =
          this._bronco.subscribeWithoutToast(broncoTaskHandle);

        broncoSubscription.taskChange$
          .pipe(
            map((event) => {
              if (event.done) {
                return 100;
              }
              return isNotNil(event.progress) ? event.progress : 0;
            }),
          )
          .subscribe((val) => this.loadingProgress.set(val));

        broncoSubscription.wait().then((task) => {
          this.close();
          const result = CreateSalesforceStoresTaskOutput.fromJsonString(
            task.output ?? '{}',
          );
          this.service.setCreatedStores(result.stores);
          this.eventBus.emit('added_salesforce_store', result.stores);
          this.next$.emit();
          this.close();
        });
      }
    } catch (e) {
      showToastException(this.toastManager, e);
      this.back$.emit();
      this.close();
    }
  }
}
