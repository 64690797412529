import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CreateSalesforceStoresRequest } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import {
  injectToastManager,
  LeftyButtonDirective,
  LeftyDialogComponent,
  LeftyFeedbackComponent,
  LeftyFormInputComponent,
  LeftyIconComponent,
  showToastException,
} from '@frontend2/ui';
import { SALESFORCE_ACCOUNT_MANAGER_URL } from '../../../../routes.constants';
import { AddSalesforceStepsComponent } from '../abstract-steps';

@Component({
  selector: 'alfred-add-salesforce-step-two-dialog',
  templateUrl: 'add-salesforce-step-two-dialog.component.html',
  styleUrls: ['../shared-dialogs-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyFeedbackComponent,
    LeftyButtonDirective,
    LeftyFormInputComponent,
    LeftyIconComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class AlfredAddSalesforceStepTwoDialogComponent extends AddSalesforceStepsComponent {
  readonly accountManagerUrl = SALESFORCE_ACCOUNT_MANAGER_URL;

  readonly toastManager = injectToastManager();

  readonly errorInStepOne$ = output<void>();

  readonly formModel: FormGroup = new FormGroup({
    apiClientId: new FormControl<string>('', Validators.required),
    secret: new FormControl<string>('', Validators.required),
  });

  override populateFormWithRequest(req: CreateSalesforceStoresRequest): void {
    this.formModel.reset({
      apiClientId: req.clientId,
      secret: req.clientSecret,
    });
  }

  setStateOfService(): void {
    const formValue = this.formModel.value;
    this.service.setStepTwoData(formValue.apiClientId, formValue.secret);
  }

  async submit(): Promise<void> {
    this.setStateOfService();
    this.loading.set(true);
    try {
      const error = await this.service.getApiClientError();
      switch (error.error.case) {
        case 'apiClientCredentialsError': {
          this.showError.set(true);
          break;
        }
        case 'organizationIdError': {
          this.errorInStepOne$.emit();
          this.close();
          break;
        }

        case 'shortCodeError': {
          this.errorInStepOne$.emit();
          this.close();
          break;
        }
        default: {
          await this.service.setSalesforceSlasAdminUILink();
          this.next();
        }
      }
    } catch (e) {
      showToastException(this.toastManager, e);
    } finally {
      this.loading.set(false);
    }
  }
}
