import {
  ApplicationRef,
  ComponentRef,
  inject,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import { outputToObservable } from '@angular/core/rxjs-interop';
import {
  createManageInfluencerEvent,
  Influencer,
  isNil,
  LeftyParentAppBridge,
  ManagementType,
} from '@frontend2/core';
import { Program } from '@frontend2/proto/librarian/proto/affiliation_pb';
import { Campaign } from '@frontend2/proto/librarian/proto/campaigns_pb';
import { Casting } from '@frontend2/proto/librarian/proto/casting_pb';
import { LandingPagePb } from '@frontend2/proto/librarian/proto/landing_pages_pb';
import { firstValueFrom } from 'rxjs';
import { AddAffiliatesDialogComponent } from '../add-affiliates-dialog/add-affiliates-dialog.component';
import { AffiliateAdditionResult } from '../affiliation/affiliates.models';
import { InfluencersSelector } from './influencers-management.models';

type ManagementEntities = {
  Campaign: Campaign;
  Casting: Casting;
  Affiliation: Program;
  LandingPage: LandingPagePb;
  Directory: undefined;
};

type Selector = Influencer | InfluencersSelector;

function isInfluencer(selector: Selector): selector is Influencer {
  return 'id' in selector;
}

@Injectable({ providedIn: 'root' })
export class InfluencersManagementService {
  readonly parentAppBridge = inject(LeftyParentAppBridge);

  private appRef = inject(ApplicationRef);

  private _getEntityId<Type extends keyof ManagementEntities>(
    entity?: ManagementEntities[Type],
  ): string {
    if (entity instanceof Campaign) {
      return entity.id.toString();
    }

    if (entity instanceof Casting) {
      return entity.id.toString();
    }

    if (entity instanceof LandingPagePb) {
      return entity.id.toString();
    }

    if (entity instanceof Program) {
      return entity.programId?.toString() ?? '';
    }

    return '';
  }

  private _getEntityName<Type extends keyof ManagementEntities>(
    entity?: ManagementEntities[Type],
  ): string {
    if (entity instanceof Campaign) {
      return entity.name;
    }

    if (entity instanceof Casting) {
      return entity.name;
    }

    if (entity instanceof LandingPagePb) {
      return entity.privateName;
    }

    if (entity instanceof Program) {
      return entity.name;
    }

    return '';
  }

  private _addTo(type: ManagementType, selector: Selector): void {
    const event = isInfluencer(selector)
      ? createManageInfluencerEvent({ influencers: [selector.baseSnippet] })
      : createManageInfluencerEvent({
          influencers: selector.influencers.map((e) => e.baseSnippet),
          selector: selector.flexibleSelector,
        });

    this.parentAppBridge.emitAddInfluencersTo(type, event);
  }

  private _removeFrom<Type extends keyof ManagementEntities>(
    type: Type,
    selector: Selector,
    entity?: ManagementEntities[Type],
  ): void {
    const event = isInfluencer(selector)
      ? createManageInfluencerEvent({
          entityId: this._getEntityId(entity),
          entityName: this._getEntityName(entity),
          influencers: [selector.baseSnippet],
        })
      : createManageInfluencerEvent({
          entityId: this._getEntityId(entity),
          entityName: this._getEntityName(entity),
          influencers: selector.influencers.map((e) => e.baseSnippet),
          selector: selector.flexibleSelector,
        });

    this.parentAppBridge.emitRemoveInfluencersFrom(type, event);
  }

  addToCampaign(selector: Selector): void {
    this._addTo('Campaign', selector);
  }

  removeFromCampaign(selector: Selector, campaign: Campaign): void {
    this._removeFrom('Campaign', selector, campaign);
  }

  addToDirectory(selector: Selector): void {
    this._addTo('Directory', selector);
  }

  removeFromDirectory(selector: Selector): void {
    this._removeFrom('Directory', selector);
  }

  addToCasting(selector: Selector): void {
    this._addTo('Casting', selector);
  }

  removeFromCasting(selector: Selector, casting: Casting): void {
    this._removeFrom('Casting', selector, casting);
  }

  addToAffiliation(selector: Selector): void {
    this._addTo('Affiliation', selector);
  }

  removeFromAffiliation(selector: Selector, program: Program): void {
    this._removeFrom('Affiliation', selector, program);
  }

  invite(selector: Selector): void {
    this._addTo('LandingPage', selector);
  }

  private addAffiliateRef:
    | ComponentRef<AddAffiliatesDialogComponent>
    | undefined = undefined;

  runAddAffiliatesFlow(): Promise<AffiliateAdditionResult> {
    // If it doesn't exist, create and append the component
    if (isNil(this.addAffiliateRef)) {
      const rootViewContainerRef =
        this.appRef.components[0].injector.get(ViewContainerRef);

      this.addAffiliateRef = rootViewContainerRef.createComponent(
        AddAffiliatesDialogComponent,
      );
    }

    this.addAffiliateRef.instance.open();
    return firstValueFrom(
      outputToObservable(this.addAffiliateRef.instance.success$),
    );
  }
}
