import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import {
  getStoreTypeOfStore,
  SALESFORCE_LOGO,
  SHOPIFY_LOGO,
} from '@frontend2/core';
import {
  EcommerceStorePb,
  EcommerceStoreType,
} from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import { SafeImageComponent } from '../../image/image';
import { LeftyIconComponent } from '../icon.component';

@Component({
  selector: 'store-type-icon',
  styleUrls: ['store-icon.component.scss'],
  template: `
    @if (isShopifyStore()) {
      @if (greyIcon()) {
        <lefty-icon
          class="logo"
          icon="shopify"
          size="large"
        ></lefty-icon>
      } @else {
        <lefty-image
          objectFit="cover"
          [src]="shopifyLogoUrl"
          class="online-logo"
        ></lefty-image>
      }
    } @else if (isSalesforceStore()) {
      @if (greyIcon()) {
        <lefty-icon
          class="logo"
          icon="salesforce"
          size="small"
        ></lefty-icon>
      } @else {
        <lefty-image
          objectFit="contain"
          [src]="salesforceLogoUrl"
          class="online-logo"
        ></lefty-image>
      }
    } @else if (isManualStore()) {
      <lefty-icon
        class="logo"
        icon="offline"
        size="large"
      ></lefty-icon>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyIconComponent, SafeImageComponent],
})
export class StoreTypeIconComponent {
  readonly storeType = input(EcommerceStoreType.UNKNOWN);

  readonly greyIcon = input(false);

  readonly shopifyLogoUrl = SHOPIFY_LOGO;
  readonly salesforceLogoUrl = SALESFORCE_LOGO;

  readonly isShopifyStore = computed(
    () => this.storeType() === EcommerceStoreType.SHOPIFY,
  );
  readonly isSalesforceStore = computed(
    () => this.storeType() === EcommerceStoreType.SALESFORCE,
  );
  readonly isManualStore = computed(
    () => this.storeType() === EcommerceStoreType.MANUAL,
  );
}

@Component({
  selector: 'store-icon',
  styleUrls: ['store-icon.component.scss'],
  template: ` <store-type-icon [storeType]="storeType()"></store-type-icon> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoreTypeIconComponent],
})
export class StoreIconComponent {
  readonly store = input(new EcommerceStorePb());

  readonly greyIcon = input(false);

  readonly shopifyLogoUrl = SHOPIFY_LOGO;
  readonly salesforceLogoUrl = SALESFORCE_LOGO;

  readonly storeType = computed(() => getStoreTypeOfStore(this.store()));
}
