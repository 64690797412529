import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CreateSalesforceStoresRequest } from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';
import {
  injectToastManager,
  LeftyButtonDirective,
  LeftyDialogComponent,
  LeftyFeedbackComponent,
  LeftyFormInputComponent,
  LeftyIconComponent,
  showToastException,
} from '@frontend2/ui';
import { AddSalesforceStepsComponent } from '../abstract-steps';

@Component({
  selector: 'alfred-add-salesforce-step-three-dialog',
  templateUrl: 'add-salesforce-step-three-dialog.component.html',
  styleUrls: ['../shared-dialogs-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyFeedbackComponent,
    LeftyButtonDirective,
    LeftyFormInputComponent,
    LeftyIconComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class AlfredAddSalesforceStepThreeDialogComponent extends AddSalesforceStepsComponent {
  readonly salesforceAdminUrl = computed(
    () => this.service.state().salesforceSlasAdminUILink,
  );

  readonly toastManager = injectToastManager();

  formModel: FormGroup = new FormGroup({
    slasAdminToken: new FormControl<string>('', Validators.required),
  });

  override populateFormWithRequest(req: CreateSalesforceStoresRequest): void {
    this.formModel.reset({
      slasAdminToken: req.slasAdminToken,
    });
  }

  setStateOfService(): void {
    const formValue = this.formModel.value;
    this.service.setStepThreeData(formValue.slasAdminToken);
  }

  async submit(): Promise<void> {
    this.setStateOfService();
    this.loading.set(true);
    try {
      const error = await this.service.getSlasAdminTokenError();
      if (error) {
        this.showError.set(true);
      } else {
        this.next();
      }
    } catch (e) {
      showToastException(this.toastManager, e);
    } finally {
      this.loading.set(false);
    }
  }
}
