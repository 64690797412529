<alfred-add-salesforce-intro-dialog
  (next$)="stepOne.openWithError(false)"
  #intro
/>
<alfred-add-salesforce-step-one-dialog
  (next$)="stepTwo.open()"
  (back$)="intro.open()"
  #stepOne
/>
<alfred-add-salesforce-step-two-dialog
  #stepTwo
  (back$)="stepOne.openWithError(false)"
  (next$)="stepThree.open()"
  (errorInStepOne$)="stepOne.openWithError(true)"
/>
<alfred-add-salesforce-step-three-dialog
  #stepThree
  (back$)="stepTwo.open()"
  (next$)="stepFour.open()"
/>
<alfred-add-salesforce-step-four-dialog
  (back$)="stepThree.open()"
  (next$)="connecting.open()"
  #stepFour
></alfred-add-salesforce-step-four-dialog>
<alfred-add-salesforce-connecting-dialog
  (back$)="stepFour.open()"
  (next$)="summary.open()"
  #connecting
></alfred-add-salesforce-connecting-dialog>
<alfred-add-salesforce-summary-dialog
  #summary
></alfred-add-salesforce-summary-dialog>
