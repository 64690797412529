<lefty-dialog
  [(visible)]="visible"
  size="small"
>
  <h3
    title
    i18n
  >
    Step 1: API Settings
  </h3>
  <div class="basic-container">
    <lefty-feedback
      [hideIcon]="true"
      i18n
    >
      Access your Business Manager on Salesforce Commerce Cloud and share with
      us the information below.
    </lefty-feedback>

    @if (showError()) {
      <lefty-feedback
        type="error"
        i18n
      >
        {{ errorMessage() }}
      </lefty-feedback>
    }

    <form
      [formGroup]="formModel"
      class="form-item"
    >
      <lefty-form-input
        label="Short code"
        i18n-label
        formControlName="shortCode"
      ></lefty-form-input>

      <lefty-form-input
        label="Organization ID"
        i18n-label
        formControlName="organizationId"
      ></lefty-form-input>
    </form>
    <a
      class="guide"
      [attr.href]="guideUrl"
      target="_blank"
    >
      <lefty-icon
        icon="book"
        size="medium"
      ></lefty-icon>
      <u i18n>Salesforce Commerce Cloud Integration guide</u>
    </a>
  </div>
  <ng-container footer>
    <button
      leftyButton="secondary"
      i18n
      (click)="back()"
    >
      Back
    </button>
    <button
      leftyButton="primary"
      i18n
      (click)="next()"
      [disabled]="formModel.invalid"
    >
      Next
    </button>
  </ng-container>
</lefty-dialog>
