import {
  CreateSalesforceStoresRequest,
  SalesforceStorePb,
} from '@frontend2/proto/librarian/proto/ecommerce_stores_pb';

export interface AddSalesforceStoreState {
  readonly request: CreateSalesforceStoresRequest;
  readonly broncoTaskHandle: string;
  readonly createdStores: SalesforceStorePb[];
  readonly errorMessage: string;
  readonly invalidFieldName?: InvalidFieldName;
  readonly salesforceSlasAdminUILink: string;
}

export function createAddSalesforceStoreState(
  partial?: Partial<AddSalesforceStoreState>,
): AddSalesforceStoreState {
  return {
    request: partial?.request ?? new CreateSalesforceStoresRequest(),
    broncoTaskHandle: partial?.broncoTaskHandle ?? '',
    createdStores: partial?.createdStores ?? [],
    errorMessage: partial?.errorMessage ?? '',
    invalidFieldName: partial?.invalidFieldName,
    salesforceSlasAdminUILink: partial?.salesforceSlasAdminUILink ?? '',
  };
}

export type InvalidFieldName =
  | 'apiClientCredentialsError'
  | 'shortCodeError'
  | 'organizationIdError';
