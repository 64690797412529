import {
  ChangeDetectionStrategy,
  Component,
  inject,
  output,
} from '@angular/core';
import { SALESFORCE_FULL_LOGO } from '@frontend2/core';
import {
  DialogBase,
  LeftyButtonDirective,
  LeftyDialogComponent,
  SafeImageComponent,
} from '@frontend2/ui';
import { SALESFORCE_INTEGRATION_GUIDE_URL } from '../../../../routes.constants';
import { AddSalesforceStoreBloc } from '../add-salesforce-store-bloc';

@Component({
  selector: 'alfred-add-salesforce-intro-dialog',
  templateUrl: 'add-salesforce-intro-dialog.component.html',
  styleUrls: ['./add-salesforce-intro-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyDialogComponent, SafeImageComponent, LeftyButtonDirective],
})
export class AlfredAddSalesforceIntroDialogComponent extends DialogBase {
  readonly salesForceLogo = SALESFORCE_FULL_LOGO;
  readonly guideUrl = SALESFORCE_INTEGRATION_GUIDE_URL;

  readonly service = inject(AddSalesforceStoreBloc);

  readonly next$ = output<void>();

  next(): void {
    this.close();
    this.next$.emit();
  }

  override open(): void {
    this.service.resetState();
    super.open();
  }
}
