<lefty-dialog
  [(visible)]="visible"
  size="small"
>
  <h3
    title
    i18n
  >
    Step 2: Admin API
  </h3>
  <div class="basic-container">
    <lefty-feedback
      [hideIcon]="true"
      i18n
    >
      <div class="feedback-with-link">
        <p class="fill-space">
          Follow the link to Account Manager and share with us the information
          below.
        </p>
        <a
          action
          [attr.href]="accountManagerUrl"
          target="_blank"
          >Access link</a
        >
      </div>
    </lefty-feedback>

    @if (showError()) {
      <lefty-feedback
        type="error"
        i18n
      >
        {{ errorMessage() }}
      </lefty-feedback>
    }

    <form
      [formGroup]="formModel"
      class="form-item"
    >
      <lefty-form-input
        label="API Client ID"
        i18n-label
        formControlName="apiClientId"
      ></lefty-form-input>

      <lefty-form-input
        label="Password"
        i18n-label
        type="Password"
        formControlName="secret"
      ></lefty-form-input>
    </form>
    <a
      class="guide"
      [attr.href]="guideUrl"
      target="_blank"
    >
      <lefty-icon
        icon="book"
        size="medium"
      ></lefty-icon>
      <u i18n>Salesforce Commerce Cloud Integration guide</u>
    </a>
  </div>
  <ng-container footer>
    <button
      leftyButton="secondary"
      i18n
      (click)="back()"
      [disabled]="loading()"
    >
      Back
    </button>
    <button
      leftyButton="primary"
      i18n
      [disabled]="loading() || formModel.invalid"
      [loading]="loading()"
      (click)="submit()"
    >
      Next
    </button>
  </ng-container>
</lefty-dialog>
