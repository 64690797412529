<lefty-dialog
  [(visible)]="visible"
  class="small"
  (close$)="resetForm()"
>
  <h3 title>
    {{ title() }}
  </h3>

  <p
    i18n
    description
  >
    An offline store allows you to manually record products and gifts but has to
    be manually managed as it is not connected to any ecommerce platform.
  </p>

  <form
    [formGroup]="formModel"
    class="grid"
  >
    <div class="col-12">
      <lefty-form-input
        formControlName="title"
        label="Title"
        i18n-label
        placeholder="My Store UK"
      >
      </lefty-form-input>
    </div>

    <div class="col-12">
      <lefty-form-input
        formControlName="url"
        label="URL"
        i18n-label
        [optional]="true"
        placeholder="www.mystore.uk"
      >
      </lefty-form-input>
    </div>

    <div class="col-5">
      <lefty-currency-selector
        formControlName="currency"
        label="Currency"
        i18n-label
        [disabled]="isEdit()"
      >
      </lefty-currency-selector>
    </div>
  </form>

  <ng-container footer>
    <button (click)="close()">
      {{ Messages.cancel }}
    </button>
    <button
      class="primary"
      (click)="submit()"
      [disabled]="formModel.invalid"
    >
      {{ submitButtonText() }}
    </button>
  </ng-container>
</lefty-dialog>
