<lefty-dialog
  [(visible)]="visible"
  size="small"
>
  <h3
    title
    i18n
  >
    Connecting stores
  </h3>
  <div class="container">
    @for (store of storesSuccessMap(); track $index) {
      <div class="info-row">
        @if (store[1] === true) {
          <lefty-icon
            icon="success_feedback"
            class="successful-icon"
          ></lefty-icon>
          <p>{{ store[0] }} <span i18n>successfully connected.</span></p>
        } @else {
          <lefty-icon
            icon="error_feedback"
            class="error-icon"
          ></lefty-icon>
          <p>{{ store[0] }} <span i18n>failed to be connected.</span></p>
        }
      </div>
    }
  </div>

  <ng-container footer>
    <button
      leftyButton="primary"
      i18n
      (click)="close()"
    >
      Ok
    </button>
  </ng-container>
</lefty-dialog>
