import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import {
  DialogBase,
  LeftyButtonDirective,
  LeftyDialogComponent,
  LeftyIconComponent,
} from '@frontend2/ui';
import { AddSalesforceStoreBloc } from '../add-salesforce-store-bloc';

@Component({
  selector: 'alfred-add-salesforce-summary-dialog',
  templateUrl: 'add-salesforce-summary-dialog.component.html',
  styleUrls: [
    './add-salesforce-summary-dialog.component.scss',
    '../shared-dialogs-styles.scss',
  ],
  standalone: true,
  imports: [LeftyDialogComponent, LeftyButtonDirective, LeftyIconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlfredAddSalesforceSummaryDialogComponent extends DialogBase {
  readonly service = inject(AddSalesforceStoreBloc);

  readonly storesSuccessMap = signal(new Map<string, boolean>());

  override close(): void {
    this.service.resetState();
    super.close();
  }

  override open(): void {
    this.storesSuccessMap.set(this.getSuccessOfStoreCreation());
    super.open();
  }

  getSuccessOfStoreCreation(): Map<string, boolean> {
    const map = new Map<string, boolean>();
    const requestedStores = this.service.currentRequest().siteIds;
    const successfulSiteIds = this.service
      .state()
      .createdStores.map((s) => s.siteId);

    requestedStores.forEach((store) => {
      map.set(store, successfulSiteIds.includes(store));
    });

    return map;
  }
}
