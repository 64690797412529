<lefty-dialog
  [(visible)]="visible"
  size="small"
>
  <h3
    title
    i18n
  >
    Salesforce Commerce Cloud Integration
  </h3>
  <div class="intro-container">
    <safe-image
      [src]="salesForceLogo"
      class="salesforce-logo"
    ></safe-image>
    <p class="description">
      <span i18n>Follow our step-by-step</span>
      <a
        [attr.href]="guideUrl"
        target="_blank"
        i18n
      >
        Salesforce Commerce Cloud Integration guide</a
      >
      <span i18n>
        to make the most of your experience (highly recommended)</span
      >
    </p>
  </div>

  <ng-container footer>
    <button
      leftyButton="secondary"
      i18n
      (click)="close()"
    >
      Cancel
    </button>
    <button
      leftyButton="primary"
      i18n
      (click)="next()"
    >
      Start
    </button>
  </ng-container>
</lefty-dialog>
