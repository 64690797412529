import { Injectable } from '@angular/core';
import { Feature } from '@frontend2/proto/librarian/proto/common_pb';
import { LeftyAuthBloc } from './auth.bloc';

@Injectable({ providedIn: 'root' })
export class FeaturesService {
  constructor(private auth: LeftyAuthBloc) {}

  hasFeature(features: Feature[]): boolean {
    const user = this.auth.user;

    return features.every((f) => user.limitations?.features.includes(f));
  }
}
