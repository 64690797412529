import { ChangeDetectionStrategy, Component, viewChild } from '@angular/core';
import { SALESFORCE_FULL_LOGO } from '@frontend2/core';
import { AddSalesforceStoreBloc } from './add-salesforce-store-bloc';
import { AlfredAddSalesforceConnectingDialogComponent } from './connecting/add-salesforce-connecting-dialog.component';
import { AlfredAddSalesforceIntroDialogComponent } from './intro/add-salesforce-intro-dialog.component';
import { AlfredAddSalesforceStepOneDialogComponent } from './step-1/add-salesforce-step-one-dialog.component';
import { AlfredAddSalesforceStepTwoDialogComponent } from './step-2/add-salesforce-step-two-dialog.component';
import { AlfredAddSalesforceStepThreeDialogComponent } from './step-3/add-salesforce-step-three-dialog.component';
import { AlfredAddSalesforceStepFourDialogComponent } from './step-4/add-salesforce-step-four-dialog.component';
import { AlfredAddSalesforceSummaryDialogComponent } from './summary/add-salesforce-summary-dialog.component';

@Component({
  selector: 'alfred-add-salesforce-store-dialog',
  templateUrl: 'add-salesforce-store-dialog.component.html',
  styleUrls: ['./add-salesforce-store-dialog.component.scss'],
  providers: [AddSalesforceStoreBloc],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AlfredAddSalesforceIntroDialogComponent,
    AlfredAddSalesforceStepOneDialogComponent,
    AlfredAddSalesforceStepTwoDialogComponent,
    AlfredAddSalesforceStepThreeDialogComponent,
    AlfredAddSalesforceStepFourDialogComponent,
    AlfredAddSalesforceConnectingDialogComponent,
    AlfredAddSalesforceSummaryDialogComponent,
  ],
})
export class AlfredAddSalesforceStoreDialogComponent {
  // a component that encapsulates all salesforce creation dialogs and service
  readonly salesForceLogo = SALESFORCE_FULL_LOGO;

  open(): void {
    this.introDialog().open();
  }

  readonly introDialog = viewChild.required(
    AlfredAddSalesforceIntroDialogComponent,
  );
}
