import {
  ChangeDetectionStrategy,
  Component,
  computed,
  output,
  signal,
} from '@angular/core';
import {
  createChip,
  getAllCategoryParents,
  getCategoryFromId,
  getTopLevelCategories,
  isNotNil,
} from '@frontend2/core';
import { SalesforceCategoryPb } from '@frontend2/proto/librarian/proto/salesforce_pb';
import { LeftyChipComponent } from '../../lefty-chip/lefty-chip.component';

@Component({
  selector: 'lefty-categories-grid',
  template: `
    <div
      class="category-chips-container"
      #container
    >
      <ng-content select="lefty-chip"></ng-content>
      @for (chip of categoriesChip(); track $index) {
        <lefty-chip
          [chip]="chip"
          [dark]="true"
          (remove$)="removeCategory.emit(chip.value)"
        ></lefty-chip>
      }
    </div>
  `,
  styleUrls: ['lefty-categories-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyChipComponent],
})
export class LeftyCategoriesGridComponent {
  readonly removeCategory = output<SalesforceCategoryPb>();

  private readonly selectedCategoryIds = signal<string[]>([]);

  private readonly categories = signal<SalesforceCategoryPb[]>([]);

  private readonly topCategoryIds = computed(() =>
    getTopLevelCategories(this.categories(), this.selectedCategoryIds()),
  );

  private readonly topCategories = computed(() =>
    this.topCategoryIds()
      .map((id) => getCategoryFromId(id, this.categories()))
      .filter(isNotNil),
  );

  readonly categoriesChip = computed(() =>
    this.topCategories().map((c) =>
      createChip<SalesforceCategoryPb>(c, {
        formattedValue: this._getCategoryChipLabel(c),
      }),
    ),
  );

  updateCategories(
    selectedIds: string[],
    categories: SalesforceCategoryPb[],
  ): void {
    this.selectedCategoryIds.set(selectedIds);
    this.categories.set(categories);
  }

  private _getCategoryChipLabel(category: SalesforceCategoryPb): string {
    const parents = getAllCategoryParents(category.id, this.categories());
    switch (parents.length) {
      case 0:
        return category.name;
      case 1:
        return `${parents.at(0)?.name}/${category.name}`;
      default:
        return `${parents.at(0)?.name}/.../${category.name} `;
    }
  }
}
